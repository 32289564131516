import useServices from "@/hooks/useServices";
import { ServiceCard } from "@/ui/ServiceCard";
import cn from "classnames";
import { Container } from "react-bootstrap";

import classes from "./styles.module.scss";

export default function ({
  className = "py-5",
  title,
  description,
  prefix,
  EditableText,
  EditableHtml,
  theme = "light",
  about_us_page
}) {

  const services = useServices();

  return (
    <div className={cn(className, classes.ServicesNonSlider)} data-bs-theme={theme}>
      <Container fluid="md" className="mw-xl text-center mb-5">
        <EditableText path="title" tag="h2">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </EditableText>
        <EditableHtml path="description">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </EditableHtml>
      </Container>

      <div className={classes.ServicesGrid}>
        {services.map(({ name, content, description }, idx) => (
          <ServiceCard key={idx} idx={idx} name={name}>
            {/* <span dangerouslySetInnerHTML={{ __html: about_us_page ? description : content }} /> */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </ServiceCard>
        ))}
      </div>
    </div>
  );
}
